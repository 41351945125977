import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Banner2 from "../../../components/Banner2";
import './saisonniere.css'
function LocationSaisonniere() {
    const [isOpen, setIsOpen] = useState(false);
    const [adults, setAdults] = useState(0);
    const [children, setChildren] = useState(0);

    // Fonction pour gérer le toggle de l'affichage du dropdown
    const toggleDropdown = (e) => {
        e.preventDefault(); // Empêche le comportement par défaut
        setIsOpen(prev => !prev);
    };

    // Fonction pour fermer le dropdown si un clic se produit à l'extérieur
    const closeDropdown = (e) => {
        if (!e.target.closest('.dropdown')) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeDropdown);
        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, []);

    // Fonctions pour incrémenter et décrémenter le nombre d'adultes et d'enfants
    const incrementAdults = () => setAdults(adults + 1);
    const decrementAdults = () => setAdults(adults > 0 ? adults - 1 : 0);
    const incrementChildren = () => setChildren(children + 1);
    const decrementChildren = () => setChildren(children > 0 ? children - 1 : 0);
    return (
        <>

            <Header title={"Location Residentielle"} />
            {/*  //  <Banner2 title={"Location Residentielle"} /> */}

            <div className="sub-banner overview-bgi" style={{
                background: 'rgba(0, 0, 0, 0.642) url(https://i.likibu.com/destinations/covers/africa-cover.jpg) top left repeat',
                backgroundSize: 'cover',
                height: '550px',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                position: 'relative'
            }}>

                <div className="container">
                    <div className="breadcrumb-area">
                        <h1>Airbnb en Afrique : Entre Tradition et Innovation, une Nouvelle Façon de Voyager</h1>
                        <div className="container">
                            <div className="filter">
                                <form className="filter-form">
                                    <div className="filter-form__input">
                                        <label className="filter-form__label" htmlFor="search-select">
                                            Destination:
                                        </label>
                                        <select
                                            className="form__input filter-form__select"
                                            name="sorting"
                                            id="sort-select"
                                        >
                                            <option value="cameroun">Cameroun </option>
                                            <option value="cote d'ivoire">Côte d’Ivoire </option>
                                            <option value="senegal">Sénégal  </option>
                                            <option value="mali">Mali  </option>
                                            <option value="burkina faso">Burkina Faso  </option>
                                            <option value="sao tomé et principe">Sao Tomé-et-Principe  </option>
                                        </select>
                                    </div>
                                    <div className="filter-form__input filter-form__icon">
                                        <label className="filter-form__label" htmlFor="search-select">
                                            Dates:
                                        </label>
                                        <input
                                            className="form__input search-form__input"
                                            type="date"
                                            placeholder="Start Date"
                                        />
                                        <input
                                            className="form__input search-form__input"
                                            type="date"
                                            placeholder="End Date"
                                        />
                                    </div>
                                    <div className="filter-form__input filter-form__icon">
                                        <label className="filter-form__label" htmlFor="sort-select" style={{ top: "-39px" }}>
                                            Voyageurs:
                                        </label>

                                        <div className="dropdown" style={{ position: "absolute" }}>

                                            <dt className="form__input filter-form__select" >
                                                <a href="#" onClick={toggleDropdown} className="dropdown-toggle">

                                                    <p className="multiSel">
                                                        Adultes : {adults}, Enfants : {children}
                                                    </p>
                                                </a>
                                            </dt>
                                            {isOpen && (
                                                <dd>
                                                    <div className="mutliSelect">
                                                        <ul>
                                                            <li>
                                                                <label>
                                                                    Adultes:
                                                                    <button onClick={decrementAdults} className="btn-change">-</button>
                                                                    <span className="count">{adults}</span>
                                                                    <button onClick={incrementAdults} className="btn-change">+</button>
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    Enfants :
                                                                    <button onClick={decrementChildren} className="btn-change">-</button>
                                                                    <span className="count">{children}</span>
                                                                    <button onClick={incrementChildren} className="btn-change">+</button>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </dd>
                                            )}

                                        </div>

                                    </div>
                                    <button className="btn btn-fill btn--form">Recherchez</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="services-3 content-area pt-5">
                <div className="container">
                    <div className="main-title">
                        <h1>Nos formules </h1>
                        <p></p>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-4 col-md-6 col-sm-6 s-brd-2 wow fadeInLeft delay-04s"
                            style={{ visibility: "visible", animationName: "fadeInLeft" }}
                        >
                            <div className="services-info-3">
                                <i className="flaticon-hotel-building" />
                                <h5>Essentielle</h5>
                                <div className="blog_text">
                                    <p>
                                        Description : Choisissez simplement votre logement haut gamme  parmi notre sélection exclusive et profitez de votre séjour en toute autonomie.
                                        Idéal pour ceux qui recherchent l'élégance et le confort sans services supplémentaires.
                                    </p>
                                    <p>
                                        Inclus : Accès au logement haut gamme choisi , avec toutes les commodités modernes et un service de base.
                                    </p>
                                </div>
                                <div className="" style={{ paddingBottom: 10, marginTop: 20 }}>
                                    <a
                                        className="btn btn-launch btn-lg text-white"
                                        href="#"
                                        target="_blank"
                                        rel="noopener"
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "auto"
                                        }}
                                    >
                                        +0€
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp delay-04s"
                            style={{ visibility: "visible", animationName: "fadeInUp" }}
                        >
                            <div className="services-info-3">
                                <i className="flaticon-house" />
                                <h5>Prestige </h5>
                                <div className="blog_text">
                                    <p>
                                        Description : Combinez le luxe de votre logement avec notre service de conciergerie pour un séjour sans stress.
                                    </p>
                                    <p>
                                        Voiturier, gouvernante, activités personnalisées et plus encore sont à votre disposition pour que vous puissiez vous concentrer sur la détente et la découverte.
                                    </p>
                                    <p>
                                        Inclus : Villa de luxe + conciergerie complète (voiturier, gouvernante, organisation des activités, transferts, etc.)
                                    </p>
                                </div>
                                <div className="" style={{ paddingBottom: 10, marginTop: 20 }}>
                                    <a
                                        className="btn btn-launch btn-lg text-white"
                                        href="#"
                                        target="_blank"
                                        rel="noopener"
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "auto"
                                        }}
                                    >
                                        Demandez un devis
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 col-sm-6 wow fadeInDown delay-04s"
                            style={{ visibility: "visible", animationName: "fadeInDown" }}
                        >
                            <div className="services-info-3">
                                <i className="flaticon-call-center-agent" />
                                <h5>Sur-Mesure</h5>
                                <div className="blog_text">
                                    <p>
                                        Description : Créez un séjour sur mesure avec des services personnalisés, des expériences uniques et une gestion complète de vos besoins.
                                    </p>
                                    <p>
                                        Le luxe à votre image.
                                        Ce qui est inclus : Villa + conciergerie complète + services personnalisés (chef privé, excursions, etc...)
                                    </p>
                                </div>
                                <div className="" style={{ paddingBottom: 10, marginTop: 20 }}>
                                    <a
                                        className="btn btn-launch btn-lg text-white"
                                        href="#"
                                        target="_blank"
                                        rel="noopener"
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "auto"
                                        }}
                                    >
                                        Demandez un devis
                                    </a>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div >





            {/* Our newslatters 2 start */}
            <div className="our-newslatters-2">
                <div className="container">
                    <div className="row inner">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="intro-text">
                                <h3>S'abonner à la Newsletter</h3>
                                <p>
                                    Inscrivez-vous pour recevoir des informations et des mises à jour. Ne vous inquiétez pas, nous n'enverrons pas de spam !
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="Subscribe-box">
                                <form className="form-inline" action="#" method="GET">
                                    <input
                                        type="text"
                                        className="form-control mb-sm-0"
                                        id="inlineFormInputName4"
                                        placeholder="Email "
                                    />
                                    <button type="submit" className="btn">
                                        Envoyer
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Our newslatters 2 end */}
            {/* Footer start */}
            <Footer />
            {/* Footer end */}
            {/* Full Page Search */}
            <div id="full-page-search">
                <button type="button" className="close">
                    ×
                </button>
                <form action="#" className="search">
                    <input type="search" defaultValue="" placeholder="type keyword(s) here" />
                    <button type="button" className="btn btn-sm btn-color">
                        Search
                    </button>
                </form>
            </div>
            {/* Property Video Modal */}
            <div
                className="modal property-modal fade"
                id="propertyModal"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 modal-left">
                                    <div className="modal-left-content">
                                        <div
                                            id="modalCarousel"
                                            className="carousel slide"
                                            data-ride="carousel"
                                        >
                                            <div className="carousel-inner" role="listbox">
                                                <div className="carousel-item active">
                                                    <iframe
                                                        className="modalIframe"
                                                        src="https://www.youtube.com/embed/5e0LxrLSzok"
                                                        allowFullScreen=""
                                                    />
                                                </div>
                                                <div className="carousel-item">
                                                    <img src="assets/img/test-alt.jpg" alt="Test ALT" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img src="assets/img/test-alt-2.jpg" alt="Test ALT" />
                                                </div>
                                            </div>
                                            <div className="btn-modal">
                                                <a
                                                    className="control control-prev"
                                                    href="#modalCarousel"
                                                    role="button"
                                                    data-slide="prev"
                                                >
                                                    <i className="fa fa-angle-left" />
                                                </a>
                                                <a
                                                    className="control control-next"
                                                    href="#modalCarousel"
                                                    role="button"
                                                    data-slide="next"
                                                >
                                                    <i className="fa fa-angle-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 modal-right">
                                    <div className="modal-right-content bg-white">
                                        <h1>
                                            <a href="#">Find Your Properties</a>
                                        </h1>
                                        <section>
                                            <h3>Features</h3>
                                            <ul className="bullets">
                                                <li>
                                                    <i className="fa fa-arrow-right" /> Double Bed
                                                </li>
                                                <li>
                                                    <i className="flaticon-swimmer" /> Swimming Pool
                                                </li>
                                                <li>
                                                    <i className="flaticon-bath" /> 2 Bathroom
                                                </li>
                                                <li>
                                                    <i className="flaticon-car-repair" /> Garage
                                                </li>
                                                <li>
                                                    <i className="flaticon-parking" /> Parking
                                                </li>
                                                <li>
                                                    <i className="flaticon-theatre-masks" /> About Theater
                                                </li>
                                                <li>
                                                    <i className="flaticon-old-typical-phone" /> Telephone
                                                </li>
                                                <li>
                                                    <i className="flaticon-green-park-city-space" /> Private
                                                    space
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h3>Overview</h3>
                                            <dl>
                                                <dt>Area</dt>
                                                <dd>2500 Sq Ft</dd>
                                                <dt>Condition</dt>
                                                <dd>New</dd>
                                                <dt>Year</dt>
                                                <dd>2020</dd>
                                                <dt>Price</dt>
                                                <dd>
                                                    <strong>$178,000</strong>
                                                </dd>
                                            </dl>
                                        </section>
                                        <a
                                            href="#"
                                            className="btn btn-show btn-theme"
                                        >
                                            Show Detail
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Off-canvas sidebar */}
            <div className="off-canvas-sidebar">
                <div className="off-canvas-sidebar-wrapper">
                    <div className="off-canvas-header">
                        <a className="close-offcanvas" href="#">
                            <span className="fa fa-times" />
                        </a>
                    </div>
                    <div className="off-canvas-content">
                        <aside className="canvas-widget">
                            <div className="logo-sitebar text-center">
                                <img src="assets/img/logos/logo.png" alt="logo" />
                            </div>
                        </aside>
                        <aside className="canvas-widget">
                            <ul className="menu">
                                <li className="menu-item menu-item-has-children">
                                    <a href="#">About</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">Properties List</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">Property Detail</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">Blog</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">About US</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#">Contact US</a>
                                </li>
                            </ul>
                        </aside>
                        <aside className="canvas-widget">
                            <ul className="social-icons">
                                <li>
                                    <a href="#">
                                        <i className="fa fa-facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-google-plus" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-linkedin" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-vk" />
                                    </a>
                                </li>
                            </ul>
                        </aside>
                    </div>
                </div>
            </div>


        </>
    );
}

export default LocationSaisonniere;
