import { APP_ROLES } from "../network/auth/Auth";
import Home from "../screens/frontend/Home/Home";
import About from "../screens/frontend/about/About";
import ChasseImmobiliere from "../screens/frontend/chasse-immo/ChasseImmobiliere";
import LancerProjet from "../screens/frontend/investissement-locatif-lancer-projet/LancerProjet";
import InvestissementLocatif from "../screens/frontend/investissement-locatif/InvestissementLocatif";
import MortgageSimulation from "../screens/frontend/mortgage-simulation/mortgage_simulation";
import LocationResidentielle from "../screens/frontend/location-residentielle/LocationResidentielle";
import LocationSaisonniere from "../screens/frontend/location-saisonniere/LocationSaisonniere";



const routes = [
  // Authentication Routes
  {
    spaceName: "auth",
    // spaceName: "login",
    secure: false,
    routes: [
      {
        component: <Home />,
        path: "/",
      },
      {
        component: <About />,
        path: "/about",
      },
      {
        component: <ChasseImmobiliere />,
        path: "/chasse-immobiliere",
      },
      {
        component: <InvestissementLocatif />,
        path: "/investissement-locatif",
      },
      {
        component: <LancerProjet />,
        path: "/lancer-projet",
      },
      {
        component: <MortgageSimulation />,
        path: "/mortgage-simulation",
      },
      {
        component: <LocationResidentielle />,
        path: "/location-residentielle",
      },
      {
        component: <LocationSaisonniere />,
        path: "/location-saisonniere",
      },
    ],
  },

  // Admin Routes
  {
    spaceName: "rider",
    secure: true,
    role: APP_ROLES.ROLE_PASSAGERS,
    routes: [
      /*  {
        component: <Profile2 />,
        path: "/profile",
      },
     */
    ],
  },
];
export default routes;
