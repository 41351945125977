import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Banner2 from "../../../components/Banner2";


function ChasseImmobiliere() {
  return (
    <>

      <Header title={"Chasse Immobilière"} />
      <Banner2 title={"Chasse Immobilière"} />



      <div className="services-5 content-area-2 bg-white">
        <div className="container">
          <div className="main-title">
            <h1>Nos services</h1>

          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="media services-info" style={{ height: "265px", background: "#af9483", color: "#ffffff" }}>
                <div className="media-body text-center">
                  <h1 className="mt-0" style={{ color: "#ffffff" }}>1</h1>
                  <p style={{ color: "#ffffff" }}>Entretien avec le client</p>
                  <p style={{ color: "#ffffff" }}>Critères de recherche </p>
                  <p style={{ color: "#ffffff" }}>Cahier de charge</p>
                  <p style={{ color: "#ffffff" }}>Simulation de financement avec la courtière</p>
                  <p style={{ color: "#ffffff" }}>Signature du mandat </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="media services-info" style={{ height: "265px", background: "#af9483", color: "#ffffff" }}>
                <div className="media-body text-center">
                  <h1 className="mt-0" style={{ color: "#ffffff" }}>2</h1>
                  <p style={{ color: "#ffffff" }}>Recherche du bien </p>
                  <p style={{ color: "#ffffff" }}>Visites des biens sélectionnés</p>
                  <p style={{ color: "#ffffff" }}>Vérification ( AG , DPE …) </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="media services-info" style={{ height: "265px", background: "#af9483", color: "#ffffff" }}>
                <div className="media-body text-center">
                  <h1 className="mt-0" style={{ color: "#ffffff" }}>3</h1>
                  <p style={{ color: "#ffffff" }}>Négociation du prix </p>
                  <p style={{ color: "#ffffff" }}>Offre d’achat</p>
                  <p style={{ color: "#ffffff" }}>Suivi promesse de vente</p>
                  <p style={{ color: "#ffffff" }}>Remise des clés</p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>



      <div className="about-us content-area-7 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="about-carousel">
                <div
                  id="carouselExampleIndicators3"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators3"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={1} />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={2} />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="assets/img/devenir-chasseur/devenir-chasseur2.jpg"
                        alt="property"
                        className="img-fluid"
                        style={{
                          height: "400px",
                          width: "100%"
                        }}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-info">
                <h3>Pourquoi faire appel à un chasseur immobilier ? </h3>
                <div className="agent-experience">
                  <p>✔ Vous n'êtes pas sur place (vous vivez à l'étranger...)</p>
                  <p>  ✔ Vous manquez de temps</p>
                  <p> ✔ Vous avez des critères de recherche particuliers</p>
                  <p> ✔ Vous voulez garantir le succès de votre achat</p>
                  <p> ✔ Vous avez besoin d'un accompagnement personnalisé</p>
                </div>

                <a href="mailto:audreycm@bk2v1mimmobilier.fr" className="btn btn-white-color mt-4 text-center" style={{ "text-transform": "none" }}>
                  <i className="fa fa-arrow-right pr-2" />
                  CONTACTEZ BK Arch’Immo
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="blog-section content-area-2 bg-white">
        <div class="container">
          <div className="main-title">
            <h1> Pourquoi un chasseur immobilier ?</h1>

          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="detail clearfix text-center">

                <p>Le chasseur de biens se révèle indispensable pour toute recherche, qu'il s'agisse d'un
                  budget modeste pour un investissement locatif ou d'un appartement de grand luxe. Il est
                  particulièrement utile pour les personnes disposant de peu de temps, qui ont besoin d’un
                  accompagnement ou qui ne sont pas sur place.</p>

                <p>Ce professionnel vous assiste à chaque étape de votre projet immobilier, jusqu'à la remise
                  des clés chez le notaire, validant avec vous chaque phase du processus. Il peut également
                  vous mettre en relation avec des courtiers en prêts immobiliers, des architectes ou des
                  artisans pour d'éventuels travaux post-achat.</p>

                <p>Faire appel à un chasseur d'appartement vous permet de gagner un temps précieux et
                  d'acheter au meilleur prix, sans stress.</p>

                <p>Le chasseur de biens est votre allié dévoué, vous offrant des conseils d'expert tout en vous
                  laissant maître de votre décision d'achat. Il ne vous mettra aucune pression et vous
                  informera des points forts et des défauts des biens visités.</p>

                <p>En France , un chasseur de biens est rémunéré exclusivement au succès, conformément à
                  la loi Hoguet, qui interdit toute demande de frais de dossier.<br /> Méfiez-vous donc de ceux qui
                  auraient un discours contraire.
                </p>

                <p>Le chasseur de biens a accès à l'intégralité du marché, y compris les biens proposés par les
                  agences immobilières, les ventes entre particuliers et les appartements off-market. Cela
                  maximise vos chances de trouver l'appartement de vos rêves.
                </p>

                <p>Recourir à un chasseur immobilier n'est pas réservé à une clientèle fortunée : rémunéré au
                  succès, ses honoraires sont souvent absorbés par la réduction de prix obtenue grâce à ses
                  talents de négociateur, vous assurant ainsi un bon investissement immobilier.</p>


              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="about-us content-area-7 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="about-carousel">
                <div
                  id="carouselExampleIndicators3"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators3"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={1} />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={2} />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="assets/img/devenir-chasseur/devenir3.jpeg"
                        alt="property"
                        className="img-fluid"
                        style={{
                          height: "655px",
                          width: "100%"
                        }}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-info text-justify">
                <h3 style={{ "text-align": "start" }}>Quelle différence avec une agence immobilière</h3>
                <div className="agent-experience">
                  <p>L'agent immobilier représente les intérêts de son client vendeur, tandis que le chasseur immobilier propose une gamme de services personnalisés à son client acheteur. </p>
                  <p>Chez BK Arch’Immo, nous nous consacrons entièrement aux acheteurs.</p>
                  <p>Vous bénéficiez d'un mandat de recherche qui vous lie à votre chasseur immobilier. </p>
                  <p>Cela vous donne accès à toutes les opportunités disponibles sur le marché. Contrairement à l'agent immobilier, qui est limité par son portefeuille de biens , le chasseur immobilier n'a pas de propriétés en stock à vous proposer.</p>
                  <p>Votre chasseur immobilier agit avec impartialité; il n'a aucun intérêt particulier à vous convaincre d'acheter un bien plutôt qu'un autre. Il n'est affilié à aucune propriété, vendeur ou intermédiaire.</p>
                  <p>Il vous présente uniquement des biens qui répondent précisément à vos critères et attentes. </p>
                  <p>De plus, il négocie le prix dans votre meilleur intérêt, visant à vous offrir le meilleur rapport qualité/prix possible. </p>
                </div>

                <a href="#" className="btn btn-white-color mt-4 text-center" style={{ "text-transform": "none" }}>
                  <i className="fa fa-arrow-right pr-2" />
                  CONTACTER BK Arch’Immo
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="about-us content-area-7  bg-white">
        <div className="container">
          <div className="row">

            <div className="col-lg-6 col-md-12">
              <div className="about-info text-justify">
                <h3>Nos tarifs de chasse immobilière </h3>
                <div className="agent-experience">
                  <p><span style={{ fontWeight: "bold", fontSize: "25px" }}>.</span> Recherche de biens ( OFF MARKET , Réseau )</p>
                  <p><span style={{ fontWeight: "bold", fontSize: "25px" }}>.</span> Pré-visites & Visites avec un chasseur immobilier</p>
                  <p><span style={{ fontWeight: "bold", fontSize: "25px" }}>.</span> Négociation & Accompagnement </p>
                  <p><span style={{ fontWeight: "bold", fontSize: "25px" }}>.</span> Mandat de recherche 3 mois (renouvellement possible)</p>
                  <p><span style={{ fontWeight: "bold", fontSize: "25px" }}>.</span> Aucune visite inutile!</p>
                  <p className="pt-4" style={{ fontWeight: "bold", fontSize: "17px", color: "#af9483" }}> Honoraires au succès  </p>
                  <p> 0€ frais de dossier </p>
                  <p> 0€ frais de recherche immobilière </p>
                  <p> Paiement à la remise des clés </p>
                </div>


              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <h3 className="text-center pb-4">Décrire votre projet </h3>
              <form action="#" method="GET" encType="multipart/form-data">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-section fs-two">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group name">
                            <label>Prénom</label>
                            <input
                              type="text"
                              name="prenom"
                              className="form-control"
                              placeholder="Prénom"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group email">
                            <label>Nom</label>
                            <input
                              type="text"
                              name="nom"
                              className="form-control"
                              placeholder="Nom"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group subject">
                            <label>E-mail</label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="E-mail"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group number">
                            <label>Téléphone</label>
                            <input
                              type="text"
                              name="phone"
                              className="form-control"
                              placeholder="Téléphone"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Budget</label>
                            <select
                              className="selectpicker search-fields selectBox form-control"
                              name="Status"

                            >
                              <option>Moins de 300 000€</option>
                              <option>De 300 000€ a 600 000€</option>
                              <option>De 600 000€ a 1 000 000€</option>
                              <option>Immobilier de prestige (plus de 2M€) </option>
                            </select>

                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Type</label>
                            <select
                              className="selectpicker search-fields selectBox form-control"
                              name="Status"

                            >
                              <option>Studio</option>
                              <option>2 pièces</option>
                              <option>3 pièces</option>
                              <option>4 pièces et +</option>
                              <option>Autres</option>
                            </select>

                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <label>Localisation</label>
                            <select
                              className="selectpicker search-fields selectBox form-control"
                              name="Status"

                            >
                              <option>Paris </option>
                              <option>Banlieue Parisienne </option>
                              <option>France </option>
                              <option>International</option>
                            </select>

                          </div>
                        </div>




                        <div className="col-lg-12 col-md-12">
                          <div className="form-group message">
                            <textarea
                              className="form-control"
                              name="message"
                              placeholder="Votre projet"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="send-btn">
                            <button type="submit" className="btn btn-color btn-md">
                              Envoyer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </form>

            </div>

          </div>
        </div>
      </div>




      {/* Our newslatters 2 start */}
      <div className="our-newslatters-2">
        <div className="container">
          <div className="row inner">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="intro-text">
                <h3>S'abonner à la Newsletter</h3>
                <p>
                  Inscrivez-vous pour recevoir des informations et des mises à jour. Ne vous inquiétez pas, nous n'enverrons pas de spam !
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="Subscribe-box">
                <form className="form-inline" action="#" method="GET">
                  <input
                    type="text"
                    className="form-control mb-sm-0"
                    id="inlineFormInputName4"
                    placeholder="Email "
                  />
                  <button type="submit" className="btn">
                    Envoyer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Our newslatters 2 end */}
      {/* Footer start */}
      <Footer />
      {/* Footer end */}
      {/* Full Page Search */}
      <div id="full-page-search">
        <button type="button" className="close">
          ×
        </button>
        <form action="#" className="search">
          <input type="search" defaultValue="" placeholder="type keyword(s) here" />
          <button type="button" className="btn btn-sm btn-color">
            Search
          </button>
        </form>
      </div>
      {/* Property Video Modal */}
      <div
        className="modal property-modal fade"
        id="propertyModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 modal-left">
                  <div className="modal-left-content">
                    <div
                      id="modalCarousel"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                          <iframe
                            className="modalIframe"
                            src="https://www.youtube.com/embed/5e0LxrLSzok"
                            allowFullScreen=""
                          />
                        </div>
                        <div className="carousel-item">
                          <img src="assets/img/test-alt.jpg" alt="Test ALT" />
                        </div>
                        <div className="carousel-item">
                          <img src="assets/img/test-alt-2.jpg" alt="Test ALT" />
                        </div>
                      </div>
                      <div className="btn-modal">
                        <a
                          className="control control-prev"
                          href="#modalCarousel"
                          role="button"
                          data-slide="prev"
                        >
                          <i className="fa fa-angle-left" />
                        </a>
                        <a
                          className="control control-next"
                          href="#modalCarousel"
                          role="button"
                          data-slide="next"
                        >
                          <i className="fa fa-angle-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 modal-right">
                  <div className="modal-right-content bg-white">
                    <h1>
                      <a href="#">Find Your Properties</a>
                    </h1>
                    <section>
                      <h3>Features</h3>
                      <ul className="bullets">
                        <li>
                          <i className="fa fa-arrow-right" /> Double Bed
                        </li>
                        <li>
                          <i className="flaticon-swimmer" /> Swimming Pool
                        </li>
                        <li>
                          <i className="flaticon-bath" /> 2 Bathroom
                        </li>
                        <li>
                          <i className="flaticon-car-repair" /> Garage
                        </li>
                        <li>
                          <i className="flaticon-parking" /> Parking
                        </li>
                        <li>
                          <i className="flaticon-theatre-masks" /> About Theater
                        </li>
                        <li>
                          <i className="flaticon-old-typical-phone" /> Telephone
                        </li>
                        <li>
                          <i className="flaticon-green-park-city-space" /> Private
                          space
                        </li>
                      </ul>
                    </section>
                    <section>
                      <h3>Overview</h3>
                      <dl>
                        <dt>Area</dt>
                        <dd>2500 Sq Ft</dd>
                        <dt>Condition</dt>
                        <dd>New</dd>
                        <dt>Year</dt>
                        <dd>2020</dd>
                        <dt>Price</dt>
                        <dd>
                          <strong>$178,000</strong>
                        </dd>
                      </dl>
                    </section>
                    <a
                      href="#"
                      className="btn btn-show btn-theme"
                    >
                      Show Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Off-canvas sidebar */}
      <div className="off-canvas-sidebar">
        <div className="off-canvas-sidebar-wrapper">
          <div className="off-canvas-header">
            <a className="close-offcanvas" href="#">
              <span className="fa fa-times" />
            </a>
          </div>
          <div className="off-canvas-content">
            <aside className="canvas-widget">
              <div className="logo-sitebar text-center">
                <img src="assets/img/logos/logo.png" alt="logo" />
              </div>
            </aside>
            <aside className="canvas-widget">
              <ul className="menu">
                <li className="menu-item menu-item-has-children">
                  <a href="#">About</a>
                </li>
                <li className="menu-item">
                  <a href="#">Properties List</a>
                </li>
                <li className="menu-item">
                  <a href="#">Property Detail</a>
                </li>
                <li className="menu-item">
                  <a href="#">Blog</a>
                </li>
                <li className="menu-item">
                  <a href="#">About US</a>
                </li>
                <li className="menu-item">
                  <a href="#">Contact US</a>
                </li>
              </ul>
            </aside>
            <aside className="canvas-widget">
              <ul className="social-icons">
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-vk" />
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>


    </>
  );
}

export default ChasseImmobiliere;
