import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Banner from "../../../components/Banner";


function Home() {
  return (
    <>

      <Header />
      <Banner />




      <div className="agent-page content-area-2">
        {/* Agent detail start */}
        <div className="agent-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="sidebar mrb">
                  <div className="agent-3">
                    <div className="agent-photo">
                      <img
                        src="assets/img/about/qui-sommes-nous.png"
                        alt="avatar-5"
                        className="img-fluid"
                      />
                    </div>
                    <div className="agent-details">
                      <h5>
                        <a href="#">BK Arch’Immo</a>
                      </h5>
                      <div className="contact">
                        <p>
                          <a href="mailto:info@themevessel.com">
                            <i className="fa fa-home" />
                            2 Villa Saint Michel 75018 Paris
                          </a>
                        </p>
                        <p>
                          <a href="tel:+33 7 45 38 22 09">
                            {" "}
                            <i className="fa fa-envelope-o" />
                            audreycm@bk2v1mimmobilier.fr
                          </a>
                        </p>
                        <p>
                          <a href="#">
                            <i className="fa fa-phone" />
                            09 56 12 43 09
                          </a>
                        </p>
                        <p>
                          <a href="#">
                            <i className="fa fa-whatsapp" />
                            +337 55 95 28 06
                          </a>
                        </p>
                        <p>
                          <a href="#">
                            <i className="fa fa-id-card-o" />
                            CPI75012015000002025
                          </a>

                        </p>
                      </div>
                      <ul className="social-list clearfix">
                        <li>
                          <a href="#" className="facebook-bg">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="twitter-bg">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="instagram-bg">
                            <i className="fa fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="linkedin-bg">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="contact-3 contact-agent widget-3">
                    <h5 className="sidebar-title">Contactez-nous</h5>
                    <form action="#" method="GET" encType="multipart/form-data">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Nom "
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="eamil"
                          className="form-control"
                          placeholder="Adresse e-mail"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Téléphone "
                        />
                      </div>

                      <div className="form-group mb-0">
                        <button
                          type="submit"
                          className="btn btn-color btn-md btn-message btn-block"
                        >
                          Envoyer
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="agent-description">
                  <h3 className="heading-3">QUI SOMMES-NOUS ?</h3>
                  <p>
                    BK Arch’Immo a été fondée en 2023 par Audrey Mballa Carpentier, chasseuse immobilière
                    expérimentée.
                  </p>
                  <p className="">
                    L’agence est née d’un constat simple : mener une recherche immobilière sur un marché
                    tendu est un véritable parcours du combattant. Entre la pénurie de biens de qualité et la
                    multiplication des interlocuteurs (agents immobiliers, courtiers, architectes…), il est facile de
                    se perdre.
                  </p>
                  <p className="">
                    Pour répondre à cette problématique, nous gérons votre projet de A à Z. Nous vous
                    accompagnons dans la recherche de votre bien (appartements, maisons, hôtels particuliers,
                    châteaux, propriétés, programmes neufs, biens d’exception...), à l’achat comme à la
                    location, ainsi que dans le montage de financement, l’aménagement, et ce jusqu’à la remise
                    des clés. Nous nous occupons également de la gestion locative. Chaque étape de votre
                    projet est prise en charge par le meilleur expert possible.

                  </p>
                  <p className="">
                    Spécialistes de la recherche immobilière personnalisée, nous intervenons sur toute
                    l'Île-de-France, en France, et à l’International. Nous sommes exclusivement au service de
                    l'acquéreur. En plus de nos services immobiliers, BK Arch’Immo propose des locations de
                    vacances dans des lieux d’exception en Afrique, conçus pour répondre à toutes vos
                    attentes. Notre conciergerie se charge de créer et d'organiser vos voyages sur mesure, pour
                    une sérénité absolue.
                  </p>
                  <p className="">
                    Que vous ayez besoin de transport, d’un transfert en hélicoptère, de la location d’une villa
                    sur une île privée ou à proximité de villages autochtones, tout est géré par nos concierges.
                    Nous nous occupons également de vos déplacements, partout en Afrique. Besoin d’un jet
                    privé, d'un yacht, ou d'une voiture avec chauffeur ? Nous savons que votre temps est
                    précieux et nos concierges s’occupent de vous emmener à destination en toute quiétude.
                    Quelles que soient vos envies d’évasion, vos besoins pour des déplacements rapides,
                    discrets et sans encombre, nos concierges et partenaires sont à votre écoute pour trouver la
                    solution parfaitement adaptée. Notre champ d’action est vaste !

                  </p>
                  <p className="">
                    Implantée sur le marché immobilier français et international, notre agence a pour mission
                    d’accompagner toutes les personnes désireuses de trouver un bien immobilier à la hauteur
                    de leurs attentes. Confiez la recherche de votre bien immobilier à une équipe
                    professionnelle, nous sommes la référence dans notre domaine. BK Arch’Immo, c’est une
                    vision et une idéologie humaniste et globale. Nos services s’adressent à tous les profils de
                    clients, qu’il s’agisse de la classe moyenne ou de la classe privilégiée.

                  </p>
                  <p className="">
                    Si vous avez besoin d’un bien immobilier standard ou d’un bien haut de gamme, vous êtes
                    au bon endroit. Trouvez le bien immobilier de vos rêves ici et maintenant !
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Agent detail end */}
      </div >


      <div className="about-us content-area-7 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="about-carousel">
                <div
                  id="carouselExampleIndicators3"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators3"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={1} />
                    <li data-target="#carouselExampleIndicators3" data-slide-to={2} />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="assets/img/avantage/avantage.jpeg"
                        alt="property"
                        className="img-fluid"
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-info">
                <h3>Les avantages du chasseur immobilier </h3>
                <div className="agent-experience">
                  <p>✔ Gain de temps et d'argent.</p>
                  <p>  ✔ Chasse immobilière hors marché</p>
                  <p> ✔ Accompagnement jusqu'à la remise des clés</p>
                  <p> ✔ Paiement uniquement en cas de succès !</p>
                  <p> ✔ Chasseurs experts</p>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>



      {/* Services 2 start */}
      < div className="services-2 overview-bgi" >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 align-self-center wow fadeInLeft delay-04s">
              <div className="main-title main-title-2">
                <h1>Comment ça marche ?
                </h1>


              </div>
            </div>
            <div className="col-lg-7 offset-lg-1 wow fadeInRight delay-04s">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="media services-info">
                    <div className="media-body d-flex">
                      <div style={{ "margin-left": "-48px", "padding-right": "9px" }}>
                        <button class="btn btn-block btn-md btn-theme" style={{
                          "border-radius": "50%",
                          "height": "60px",
                          "width": "31px"
                        }}>1</button>
                      </div>

                      <p>
                        Le chasseur de biens recherche à votre place le bien dont vous rêvez ! Contrairement à un agent immobilier il ne se limite pas au catalogue de son agence et vous permet d'accéder à l'ensemble des offres, y compris les biens vendus en Off Market (non listés sur les plateformes immobilières)

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="media services-info">
                    <div className="media-body d-flex">
                      <div style={{ "margin-left": "-48px", "padding-right": "9px" }}>
                        <button class="btn btn-block btn-md btn-theme" style={{
                          "border-radius": "50%",
                          "height": "60px",
                          "width": "31px"
                        }}>2</button>
                      </div>
                      <p>
                        Le service de chasseur immobilier  n'est pas réservé à une clientèle fortunée, il se rémunère uniquement au succès et grâce à ses talents de négociateur, les honoraires sont quasiment toujours absorbés par la baisse de prix !
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div >
      {/* Services 2 end */}
      {/* Recent Properties start */}
      <div className="recent-properties content-area-2">
        <div className="container">
          <div className="main-title">
            <h1>NOS SERVICES</h1>

          </div>
          <div className="slick-slider-area wow fadeInUp delay-04s">
            <div
              className="row slick-carousel"
              data-slick='{"slidesToShow": 5, "responsive":[{"breakpoint": 1024,"settings":{"slidesToShow": 2}}, {"breakpoint": 768,"settings":{"slidesToShow": 1}}]}'
            >

              <div className="slick-slide-item ">
                <div className="property-box-8">
                  <div className="property-photo">
                    <img
                      style={{ height: "165px" }}
                      src="https://noproblimmo.com/wp-content/uploads/2020/10/chasse-immobiliere.jpg"
                      alt="property-8"
                      className="img-fluid"
                    />


                  </div>
                  <div className="detail">
                    <div className="heading" style={{ height: "275px" }}>
                      <h3>
                        <a href="#">Chasse Immobilière</a>
                      </h3>
                      <div className="location">
                        <p>
                          Recherchez votre bien (appartement, hôtel, maison, château, local commercial) à l'achat comme la location sur toute l'Île de France .                        </p>
                      </div>
                    </div>
                    <div className="properties-listing">
                      <span>
                        <i className="flaticon-square-layouting-with-black-square-in-east-area" />{" "}
                        <a href="#" style={{ color: "#af9483" }}>En savoir plus</a>
                      </span>
                      <span>
                        <i className="fa fa-arrow-right" />
                      </span>

                    </div>
                  </div>
                </div>
              </div>

              <div className="slick-slide-item">
                <div className="property-box-8">
                  <div className="property-photo">
                    <img
                      style={{ height: "165px" }}
                      src="https://www.auxandre-gestion-patrimoine.fr/wp-content/uploads/investissement-immobilier.jpg"
                      alt="property-8"
                      className="img-fluid"
                    />


                  </div>
                  <div className="detail">
                    <div className="heading" style={{ height: "275px" }}>
                      <h3>
                        <a href="#npm start">Investissement Locatif</a>
                      </h3>
                      <div className="location">
                        <p className="mb-0">
                          Accompagnement de A à Z. Chaque étape de votre projet est gérée par le meilleur expert (Courtier, Chasseur immobilier, Architecte).
                        </p>
                      </div>
                    </div>
                    <div className="properties-listing">
                      <span>
                        <i className="flaticon-square-layouting-with-black-square-in-east-area" />{" "}
                        <a href="#" style={{ color: "#af9483" }}>En savoir plus</a>
                      </span>
                      <span>
                        <i className="fa fa-arrow-right" />
                      </span>

                    </div>
                  </div>
                </div>
              </div>

              <div className="slick-slide-item">
                <div className="property-box-8">
                  <div className="property-photo">
                    <img
                      style={{ height: "165px" }}
                      src="https://www.datocms-assets.com/7890/1666880131-renovation-appartement-plan.jpeg?auto=format&q=80"
                      alt="property-8"
                      className="img-fluid"
                    />

                  </div>
                  <div className="detail">
                    <div className="heading" style={{ height: "275px" }}>
                      <h3>
                        <a href="#npm start">Travaux et Ameublement </a>
                      </h3>
                      <div className="location">
                        <p>
                          Accompagnement dans vos projets de rénovation complète ou partielle. Des solutions sur mesure adaptées à vos besoins et à votre budget.
                        </p>
                      </div>
                    </div>
                    <div className="properties-listing">
                      <span>
                        <i className="flaticon-square-layouting-with-black-square-in-east-area" />{" "}
                        <a href="#" style={{ color: "#af9483" }}>En savoir plus</a>
                      </span>
                      <span>
                        <i className="fa fa-arrow-right" />
                      </span>

                    </div>
                  </div>
                </div>
              </div>

              <div className="slick-slide-item">
                <div className="property-box-8">
                  <div className="property-photo">
                    <img
                      style={{ height: "165px" }}
                      src="https://www.depensez.com/wp-content/uploads/2022/08/travaux-renovation-1.jpg"
                      alt="property-8"
                      className="img-fluid"
                    />


                  </div>
                  <div className="detail">
                    <div className="heading" style={{ height: "275px" }}>
                      <h3>
                        <a href="#npm start">À l’International </a>
                      </h3>
                      <div className="location">
                        <br />
                        <p className="mb-0">
                          Accompagnement pour vos projets à l’étranger: Achat + construction, rénovation, réhabilitation.
                        </p>

                      </div>
                    </div>
                    <div className="properties-listing">
                      <span>
                        <i className="flaticon-square-layouting-with-black-square-in-east-area" />{" "}
                        <a href="#" style={{ color: "#af9483" }}>En savoir plus</a>
                      </span>
                      <span>
                        <i className="fa fa-arrow-right" />
                      </span>

                    </div>
                  </div>
                </div>
              </div>

              <div className="slick-slide-item">
                <div className="property-box-8">
                  <div className="property-photo">
                    <img
                      style={{ height: "165px" }}
                      src="https://www.laconciergerievoyage.com/cdn/shop/files/Villa-location-gordes-luberon-conciergerie-voyage23_2000x.jpg?v=1712723099)"
                      alt="property-8"
                      className="img-fluid"
                    />


                  </div>
                  <div className="detail">
                    <div className="heading" style={{ height: "275px" }}>
                      <h3>
                        <a href="#npm start">Conciergerie de Voyage</a>
                      </h3>
                      <div className="location">
                        <p className="mb-0">
                          Nous créons et organisons vos voyages sur mesure, vous offrant une sérénité absolue
                        </p>


                      </div>
                    </div>
                    <div className="properties-listing">
                      <span>
                        <i className="flaticon-square-layouting-with-black-square-in-east-area" />{" "}
                        <a href="#" style={{ color: "#af9483" }}>En savoir plus</a>
                      </span>
                      <span>
                        <i className="fa fa-arrow-right" />
                      </span>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* Recent Properties end */}
      {/* Popular Places start */}
      <div className="most-popular-places content-area-3">
        <div className="container">
          <div className="main-title">
            <h1>Notre tableau de chasse</h1>
            <p>Quelque soit le bien immobilier que vous cherchez dans toute l'Île-de-France, en France ou à l’International, nos chasseurs vont  <br />le dénicher, et le négocier pour vous. </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-pad cp-3 wow fadeInLeft delay-04s">
                <div className="overview aa overview-box">
                  <img
                    src="assets/img/tableau/img001.jpeg"
                    alt="popular-places"
                    className="big-img"
                  />
                  <div className="mask">
                    <div className="info-2">
                      <h2>Paris </h2>
                      <p>Prix négocié -25%</p>
                      <a href="#npm start" className="btn btn-border">
                        Trouvé en 10 jours
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 cp-2">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-pad wow fadeInRight delay-04s">
                    <div className="overview overview-box">
                      <img
                        src="assets/img/tableau/img002.jpeg"
                        alt="popular-places"
                      />
                      <div className="mask">
                        <div className="info">
                          <h2>Paris </h2>
                          <p>Prix négocié -8%</p>
                          <a
                            href="#"
                            className="btn btn-border"
                          >
                            Trouvé en 7jours
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-pad wow fadeInRight delay-04s">
                    <div className="overview overview-box">
                      <img
                        src="assets/img/tableau/img003.jpeg"
                        alt="popular-places"
                      />
                      <div className="mask">
                        <div className="info">
                          <h2>Fontainebleau ( 77)</h2>
                          <p>Prix négocié 0%</p>
                          <a
                            href="#"
                            className="btn btn-border"
                          >
                            Trouvé en 1 jour
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-pad wow fadeInRight delay-04s">
                    <div className="overview overview-box">
                      <img
                        src="assets/img/tableau/img004.jpeg"
                        alt="popular-places"
                      />
                      <div className="mask">
                        <div className="info align-self-center">
                          <h2>Nangis ( 77) </h2>
                          <p>Prix négocié 0%</p>
                          <a
                            href="#"
                            className="btn btn-border"
                          >
                            Trouvé en 10 jours
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-12 col-md-12 cp-2 text-center " style={{ overflow: "hidden" }}>
                <a href="https://elan.ifp-school.com/VR/labenov/index.htm" className="btn btn-white-color" style={{ textTransform: "none", margin: "0 auto" }} target="_blank">
                  <i className="fa fa-arrow-right pr-2" />
                  Visite de notre réalisation pour l'Institut français du pétrole
                  <p>Pour les professionnels c’est par ici </p>

                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Popular Places end */}


      {/* services start */}
      <div className="services-5 content-area-2 pt-0">
        <div className="container">
          <div className="main-title">
            <h1>AVANT / APRÈS</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="media services-info">
                <div className="media-body">
                  <div class="row ">
                    <div class="col-sm-6 p-1">
                      <img src="assets/img/avant-apres/avant1.jpeg" style={{ width: "100%", height: "180px" }} />
                      <div class="overlay-text">
                        <a
                          href=""
                          className="btn btn-white "
                          style={{ fontSize: "12px", padding: "4px" }}
                        >
                          Avant
                        </a></div>
                    </div>
                    <div class="col-sm-6 p-1">
                      <img src="assets/img/avant-apres/apres1.jpeg" style={{ width: "100%", height: "180px" }} />
                      <div class="overlay-text">
                        <a
                          href=""
                          className="btn btn-white "
                          style={{ fontSize: "12px", padding: "4px" }}
                        >
                          Après
                        </a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="media services-info">
                <div className="media-body">
                  <div class="row ">
                    <div class="col-sm-6 p-1">
                      <img src="assets/img/avant-apres/avant2.jpeg" style={{ width: "100%", height: "180px" }} />
                      <div class="overlay-text">
                        <a
                          href=""
                          className="btn btn-white "
                          style={{ fontSize: "12px", padding: "4px" }}
                        >
                          Avant
                        </a></div>
                    </div>
                    <div class="col-sm-6 p-1">
                      <img src="assets/img/avant-apres/apres2.jpeg" style={{ width: "100%", height: "180px" }} />
                      <div class="overlay-text">
                        <a
                          href=""
                          className="btn btn-white "
                          style={{ fontSize: "12px", padding: "4px" }}
                        >
                          Après
                        </a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* services end */}



      {/* Agent start */}
      <div className="agent content-area">
        <div className="container">
          <div className="main-title">
            <h1>La Fondatrice</h1>

          </div>
          <div className="slick-slider-area">
            <div className="row slick-carousel wow fadeInUp delay-04s" data-slick='{"slidesToShow": 1, "responsive":[{"breakpoint": 1024,"settings":{"slidesToShow": 2}}, {"breakpoint": 768,"settings":{"slidesToShow": 1}}]}'>
              <div class="container">
                <div class="row justify-content-center">
                  <div className="slick-slide-item wow col-lg-9" >
                    <div className="row agent-4">
                      <div className="col-lg-4 col-md-12 col-pad">
                        <div className="photo">
                          <img src="assets/img/agent/agent2.png" alt="avatar-4" className="img-fluid" />
                          <ul className="social-list clearfix">
                            <li>
                              <a href="#" className="facebook-bg">
                                <i className="fa fa-facebook" />
                              </a>
                            </li>
                            <li>
                              <a href="#" className="twitter-bg">
                                <img src="assets/img/social-icon/twitter-x.png" />
                              </a>
                            </li>
                            <li>
                              <a href="#" className="google-bg">
                                <i className="fa fa-instagram" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-12 col-pad pl-4 align-self-center">
                        <div className="details">
                          <h3>
                            <a href="#">Audrey Mballa Carpentier</a>
                          </h3>
                          <h6>Directrice d’agence</h6>
                          <div className="contact">
                            <p>
                              <a href="mailto:info@themevessel.com">
                                <i className="fa fa-envelope-o" />
                                audreycm@bk2v1mimmobilier.fr
                              </a>
                            </p>
                            <p>
                              <a href="tel:+33 7 45 38 22 09">
                                <i className="fa fa-phone" />
                                09 56 12 43 09
                              </a>
                            </p>
                            <p>
                              <a href="tel:+33 7 45 38 22 09">
                                <i className="fa fa-whatsapp" />
                                + 337 55 95 28 06
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Agent end */}
      {/* Testimonial 4 start */}
      <div className="testimonial-4 tml-4 content-area-7 overview-bgi">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-title">
                <h1>ILS TEMOIGNENT</h1>
              </div>
            </div>
          </div>
          <div className="slick-slider-area">
            <div
              className="row slick-carousel wow fadeInUp delay-04s"
              data-slick='{"slidesToShow": 2, "responsive":[{"breakpoint": 1024,"settings":{"slidesToShow": 2}}, {"breakpoint": 768,"settings":{"slidesToShow": 1}}]}'
            >
              <div className="slick-slide-item wow">
                <div className="testimonial-inner">
                  <div className="content-box">
                    <p>
                      J'ai eu l'appartement que je voulais grâce à BK Arch’Immo en une semaine seulement.
                      Un appartement trouvé  rapidement qui a répondu à mes attentes et même un peu au delà. Je remercie l’équipe,
                      je les recommande et je ferai très certainement appel à eux pour investir à nouveau.
                    </p>
                  </div>
                  <div className="arrow-down" />
                  <div className="media user">
                    <a href="#">
                      <img
                        src="assets/img/temoignage/temoi1.jpeg"
                        alt="testimonial-4"
                        className="img-fluid"
                      />
                    </a>
                    <div className="media-body align-self-center">
                      <h5>Winston Pouka </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slick-slide-item wow">
                <div className="testimonial-inner">
                  <div className="content-box">
                    <p>
                      L’agence a assurée un suivi tout au long de l’acquisition et la rénovation du bien.
                      En plus de fournir des services de haute qualité, les équipes se sont montrées attentives à mes besoins et disponibles à chaque étape de la mission.
                    </p>
                  </div>
                  <div className="arrow-down" />
                  <div className="media user">
                    <a href="#">
                      <img
                        src="assets/img/temoignage/temoin2.jpeg"
                        alt="testimonial-4"
                        className="img-fluid"
                      />
                    </a>
                    <div className="media-body align-self-center">
                      <h5>Jeniss Penikoulou</h5>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Testimonial 4 end */}
      {/* Blog start */}
      <div className="blog content-area-2">
        <div className="container">
          <div className="main-title">
            <h1>Actualité</h1>

          </div>
          {/*  <div className="slick-slider-area">
            <div
              className="row slick-carousel wow fadeInUp delay-04s"
              data-slick='{"slidesToShow": 2, "responsive":[{"breakpoint": 1024,"settings":{"slidesToShow": 2}}, {"breakpoint": 768,"settings":{"slidesToShow": 1}}]}'
            >
              <div className="slick-slide-item wow">
                <div className="row blog-3">
                  <div className="col-lg-5 col-md-12 col-pad ">
                    <div className="photo">
                      <img
                        src="assets/img/actualite/actu1.png"
                        alt="blog-3"
                        className="img-fluid blog-img"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 col-pad">
                    <div className="detail clearfix">
                      <h3>
                        <a href="#">
                          Live instagram
                        </a>
                      </h3>

                      <div className="blog-footer clearfix">
                        <div className="float-left">
                          <p className="date">
                            <i className="flaticon-calendar" /> 27 Mars, 2024
                          </p>
                        </div>
                        <div className="float-right">
                          <a href="https://www.instagram.com/p/C431rU5o9MN/?igsh=bnJvMG80aTdxejl6" target="_blank">Voir plus</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slick-slide-item wow">
                <div className="row blog-3">
                  <div className="col-lg-5 col-md-12 col-pad ">
                    <div className="photo">
                      <img
                        src="assets/img/actualite/actu2.png"
                        alt="blog-3"
                        className="img-fluid blog-img"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 col-pad">
                    <div className="detail clearfix">
                      <h3>
                        <a href="#">
                          Rencontre avec une architecte : KEKA MBIZIMBOTE
                        </a>
                      </h3>

                      <div className="blog-footer clearfix">
                        <div className="float-left">
                          <p className="date">
                            <i className="flaticon-calendar" /> 03 Avril, 2024
                          </p>
                        </div>
                        <div className="float-right">
                          <a href="https://youtu.be/EC3Czhe8twQ?si=DST277nNdx5hL_H-" target="_blank">Voir plus</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div> */}
        </div>
      </div>
      {/* Blog start */}
      {/* Our newslatters 2 start */}
      <div className="our-newslatters-2">
        <div className="container">
          <div className="row inner">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="intro-text">
                <h3>S'abonner à la Newsletter</h3>
                <p>
                  Inscrivez-vous pour recevoir des informations et des mises à jour. Ne vous inquiétez pas, nous n'enverrons pas de spam !
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="Subscribe-box">
                <form className="form-inline" action="#" method="GET">
                  <input
                    type="text"
                    className="form-control mb-sm-0"
                    id="inlineFormInputName4"
                    placeholder="Email "
                  />
                  <button type="submit" className="btn">
                    Envoyer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Our newslatters 2 end */}
      {/* Footer start */}
      <Footer />
      {/* Footer end */}
      {/* Full Page Search */}
      <div id="full-page-search">
        <button type="button" className="close">
          ×
        </button>
        <form action="#" className="search">
          <input type="search" defaultValue="" placeholder="type keyword(s) here" />
          <button type="button" className="btn btn-sm btn-color">
            Search
          </button>
        </form>
      </div>
      {/* Property Video Modal */}
      <div
        className="modal property-modal fade"
        id="propertyModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 modal-left">
                  <div className="modal-left-content">
                    <div
                      id="modalCarousel"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                          <iframe
                            className="modalIframe"
                            src="https://www.youtube.com/embed/5e0LxrLSzok"
                            allowFullScreen=""
                          />
                        </div>
                        <div className="carousel-item">
                          <img src="assets/img/test-alt.jpg" alt="Test ALT" />
                        </div>
                        <div className="carousel-item">
                          <img src="assets/img/test-alt-2.jpg" alt="Test ALT" />
                        </div>
                      </div>
                      <div className="btn-modal">
                        <a
                          className="control control-prev"
                          href="#modalCarousel"
                          role="button"
                          data-slide="prev"
                        >
                          <i className="fa fa-angle-left" />
                        </a>
                        <a
                          className="control control-next"
                          href="#modalCarousel"
                          role="button"
                          data-slide="next"
                        >
                          <i className="fa fa-angle-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 modal-right">
                  <div className="modal-right-content bg-white">
                    <h1>
                      <a href="#">Find Your Properties</a>
                    </h1>
                    <section>
                      <h3>Features</h3>
                      <ul className="bullets">
                        <li>
                          <i className="fa fa-arrow-right" /> Double Bed
                        </li>
                        <li>
                          <i className="flaticon-swimmer" /> Swimming Pool
                        </li>
                        <li>
                          <i className="flaticon-bath" /> 2 Bathroom
                        </li>
                        <li>
                          <i className="flaticon-car-repair" /> Garage
                        </li>
                        <li>
                          <i className="flaticon-parking" /> Parking
                        </li>
                        <li>
                          <i className="flaticon-theatre-masks" /> Home Theater
                        </li>
                        <li>
                          <i className="flaticon-old-typical-phone" /> Telephone
                        </li>
                        <li>
                          <i className="flaticon-green-park-city-space" /> Private
                          space
                        </li>
                      </ul>
                    </section>
                    <section>
                      <h3>Overview</h3>
                      <dl>
                        <dt>Area</dt>
                        <dd>2500 Sq Ft</dd>
                        <dt>Condition</dt>
                        <dd>New</dd>
                        <dt>Year</dt>
                        <dd>2020</dd>
                        <dt>Price</dt>
                        <dd>
                          <strong>$178,000</strong>
                        </dd>
                      </dl>
                    </section>
                    <a
                      href="#"
                      className="btn btn-show btn-theme"
                    >
                      Show Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Off-canvas sidebar */}
      <div className="off-canvas-sidebar">
        <div className="off-canvas-sidebar-wrapper">
          <div className="off-canvas-header">
            <a className="close-offcanvas" href="#">
              <span className="fa fa-times" />
            </a>
          </div>
          <div className="off-canvas-content">
            <aside className="canvas-widget">
              <div className="logo-sitebar text-center">
                <img src="assets/img/logos/logo.png" alt="logo" />
              </div>
            </aside>
            <aside className="canvas-widget">
              <ul className="menu">
                <li className="menu-item menu-item-has-children">
                  <a href="#">Home</a>
                </li>
                <li className="menu-item">
                  <a href="#">Properties List</a>
                </li>
                <li className="menu-item">
                  <a href="#">Property Detail</a>
                </li>
                <li className="menu-item">
                  <a href="#">Blog</a>
                </li>
                <li className="menu-item">
                  <a href="#">About US</a>
                </li>
                <li className="menu-item">
                  <a href="#">Contact US</a>
                </li>
              </ul>
            </aside>
            <aside className="canvas-widget">
              <ul className="social-icons">
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-vk" />
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>


    </>
  );
}

export default Home;
